'use client';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useForm } from 'react-hook-form';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { validMobileNumber } from '@/constants/regexp/regexp.constants';
import { Campaigns } from '@/services/vin-check/VinCheckService';

import Styles from './VinSearch.module.scss';

interface FormValues {
  inspection_type?: string | number;
  firstname?: string;
  lastname?: string;
  contact_number?: string;
  email?: string;
  dealership_id?: string;
}

const VinSearchResult = ({
  setSearchResult,
  campaigns,
  searchText,
  onSubmitForm,
  dealers,
  activeDealership,
  clearSearchResult,
}: {
  setSearchResult: React.Dispatch<React.SetStateAction<boolean>>;
  campaigns: Campaigns[];
  searchText: string;
  onSubmitForm: (values: FormValues) => void;
  dealers: {
    name: string;
    id: string;
  }[];
  activeDealership?: string;
  clearSearchResult: () => void;
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      inspection_type: campaigns[0]?.campaign_number,
      firstname: '',
      lastname: '',
      contact_number: '',
      email: '',
      dealership_id: activeDealership,
    },
  });

  return (
    <>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        justifyContent={'space-between'}
        paddingBottom={{ base: '40px', lg: '80px' }}
        alignItems={'flex-start'}
      >
        <Box>
          <Box className={Styles.headingElement}>
            <Heading
              as="h2"
              className={Styles.titleXl}
              marginBottom={{ base: 4, md: 6 }}
            >
              VIN Search Result
            </Heading>
          </Box>
          <Text>Your vehicle is eligible for the following inspection.</Text>
        </Box>
        <ButtonCustom
          onClick={() => {
            clearSearchResult();
            setSearchResult(false);
          }}
          className="btnOutline"
          extraClass={Styles.ctaBtn}
        >
          <SearchIcon /> Search Again
        </ButtonCustom>
      </Stack>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        justifyContent={'space-between'}
        gap={'120px'}
        alignItems={'flex-start'}
      >
        <Box
          maxW={{ base: '100%', lg: '603px' }}
          width={'100%'}
          className={Styles.recallBox}
        >
          {campaigns?.map((campaign) => {
            return (
              <>
                <Box className={Styles.headingElement}>
                  <Text marginBottom={2}>{campaign?.type}</Text>
                  <Heading
                    as="h3"
                    className={Styles.titleLg}
                    marginBottom={{ base: 4, lg: 4 }}
                  >
                    {campaign?.model?.name}
                  </Heading>
                </Box>
                <Text as="b">{campaign?.name}</Text>
                <Text>{campaign?.description}</Text>
              </>
            );
          })}
        </Box>
        <Box
          maxW={{ base: '100%', lg: '576px' }}
          width={'100%'}
          className={Styles.recallForm}
        >
          <Box className={Styles.headingElement} marginBottom={6}>
            <Heading as="h4" className={Styles.titleMd} marginBottom={6}>
              Recall
            </Heading>
            <Text>
              Your vehicle is part of a recall action. Please complete your
              details in the field below and Kia Customer Care will contact you
              to book your vehicle in for an inspection.
            </Text>
          </Box>

          <Stack gap={4} as="form" onSubmit={handleSubmit(onSubmitForm)}>
            <FormControl isInvalid={Boolean(errors.inspection_type)}>
              <FormLabel className={Styles.formLabel}>
                {'Inspection Type'}
              </FormLabel>
              <Select
                {...register('inspection_type', {
                  required: 'Required',
                })}
                variant="flushed"
                className={Styles.formInput}
                name="inspection_type"
              >
                {campaigns?.map((dealer) => (
                  <option
                    key={dealer?.campaign_number}
                    value={dealer?.campaign_number}
                  >
                    {dealer?.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {Boolean(errors.inspection_type) &&
                  (errors.inspection_type?.message as ReactNode)}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel className={Styles.formLabel}>VIN Number</FormLabel>
              <Input
                value={searchText}
                className={Styles.formInput}
                variant="flushed"
                readOnly
              />
            </FormControl>
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              gap={{ base: 4, lg: '30px' }}
            >
              <FormControl isInvalid={Boolean(errors.firstname)}>
                <FormLabel className={Styles.formLabel}>{''}</FormLabel>
                <Input
                  {...register('firstname', {
                    required: 'Required',
                  })}
                  name="firstname"
                  className={Styles.formInput}
                  variant="flushed"
                  placeholder="First Name*"
                />
                <FormErrorMessage>
                  {Boolean(errors.firstname) &&
                    (errors.firstname?.message as ReactNode)}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.lastname)}>
                <FormLabel className={Styles.formLabel}>{''}</FormLabel>
                <Input
                  {...register('lastname', {
                    required: 'Required',
                  })}
                  name="lastname"
                  className={Styles.formInput}
                  variant="flushed"
                  placeholder="Last Name*"
                />
                <FormErrorMessage>
                  {Boolean(errors.lastname) &&
                    (errors.lastname?.message as ReactNode)}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <FormControl isInvalid={Boolean(errors.contact_number)}>
              <FormLabel className={Styles.formLabel}>{''}</FormLabel>
              <Input
                {...register('contact_number', {
                  required: 'Required',
                  pattern: {
                    value: validMobileNumber,
                    message: 'Entered value does not match phone format',
                  },
                })}
                name="contact_number"
                className={Styles.formInput}
                variant="flushed"
                placeholder="Contact Number*"
              />
              <FormErrorMessage>
                {Boolean(errors.contact_number) &&
                  (errors.contact_number?.message as ReactNode)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.email)}>
              <FormLabel className={Styles.formLabel}>{''}</FormLabel>
              <Input
                {...register('email', {
                  required: 'Required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                })}
                type="email"
                name="email"
                className={Styles.formInput}
                variant="flushed"
                placeholder="Email Address*"
              />
              <FormErrorMessage>
                {Boolean(errors.email) && (errors.email?.message as ReactNode)}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.dealership_id)}>
              <FormLabel className={Styles.formLabel}>{''}</FormLabel>
              <Select
                {...register('dealership_id', {
                  required: 'Required',
                })}
                variant="flushed"
                className={Styles.formInput}
                placeholder="Kia Dealer*"
                name="dealership_id"
              >
                {dealers?.map((dealer) => (
                  <option key={dealer?.id} value={dealer?.id}>
                    {dealer?.name}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {Boolean(errors.dealership_id) &&
                  (errors.dealership_id?.message as ReactNode)}
              </FormErrorMessage>
            </FormControl>

            <Box
              width={{ base: '100%', md: 'max-content' }}
              marginLeft={'auto'}
            >
              <ButtonCustom extraClass={Styles.ctaBtn} type="submit">
                Submit
              </ButtonCustom>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

export default VinSearchResult;
